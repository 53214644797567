import React from 'react';
import { Hidden, Grid } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

import { withQuoteWizard } from '../components/withQuoteWizard';

import { GuidesPage } from '../imageURLs';

import SEO from '../components/SEO';

import Hero from '../components/Hero/Hero';
import CtaCard from '../components/CtaCard/CtaCard';
import TextBlock from '../components/TextBlock';
import CenterContent from '../components/CenterContent';

const styles = theme =>
  createStyles({
    cards: {
      backgroundColor: '#FFFFFF',
    },
  });

const ThePage = ({ data, classes, children }) => (
  <React.Fragment>
    <SEO
      title="Turf Care Guides - Twin View Turf - Producer of Australia’s Best Lawns"
      keywords={[
        `Turf care guides`,
        `care guides`,
        `Turf`,
        `Lawns`,
        `Best Lawns`,
        `Australia’s Best Lawns`,
        `lawn producer`,
      ]}
    />

    <Hero heroImage={GuidesPage.hero} noQuote>
      <TextBlock text="Guides" />
    </Hero>

    <Grid container item className={classes.cards}>
      <CenterContent style={{ maxWidth: 960 }}>
        <CtaCard
          image={GuidesPage.article1}
          header="Measuring your site"
          text="Learn the basics of measuring your site with these four easy steps."
          ctaHref="/guides/measuring-your-site"
          cardType="article"
          ctaText="Read more"
        />
        <CtaCard
          image={GuidesPage.article2}
          header="New Lawns"
          text="Find out the best way to prepare your brand new site for a new lawn."
          ctaHref="/guides/new-lawns"
          cardType="article"
          ctaText="Read more"
        />
      </CenterContent>
    </Grid>

    <Grid container item className={classes.cards}>
      <CenterContent style={{ maxWidth: 960 }}>
        <CtaCard
          image={GuidesPage.article3}
          header="Replacement Lawns"
          text="Follow this step-by-step guide on lawn replacements to give your new lawn the best start."
          ctaHref="/guides/replacement-lawns"
          cardType="article"
          ctaText="Read more"
        />
        <Hidden smDown>
          <Grid container item md={6}>
            {/* for 3 articles to align well */}
          </Grid>
        </Hidden>
      </CenterContent>
    </Grid>

    {children}
  </React.Fragment>
);

export default withQuoteWizard(withStyles(styles, { withTheme: true })(ThePage));
