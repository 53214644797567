import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill"

const HeroImage = ({ relativePath, ...rest }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          ctaImages: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                    originalImg
                  }
                }
              }
            }
          }
        }
      `}
      render={({ ctaImages }) => {
        const ctaImage = ctaImages.edges.find(image => image.node.relativePath === relativePath);

        if (!ctaImage) return null;

        return <Img fluid={ctaImage.node.childImageSharp.fluid} style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover', /* Do not scale the image */
          objectPosition: 'center',
        }} {...rest} />
      }}
    />
  )
}

export default HeroImage;
