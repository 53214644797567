import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Slide,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ExpandMore } from '@material-ui/icons';

import ensureGtag from '../../helpers/ensureGtag';

import submitForm from '../../helpers/submitForm';
import ResultSnackbar from '../ResultSnackbar/ResultSnackbar';
import Select from '../Compare/Select';
import Input from './Input';
import AutoComplete from '../Autocomplete/Autocomplete';
import { pxToRem } from '../../theme';
import { suburbOptions } from './suburbs';

const EmailQuote = props => {
  if (props.isEmailingQuote) {
    return (
      <form onSubmit={props.onSubmit} className={props.classes.quoteForm}>
        <Slide in direction="up">
          <div>
            <Input
              placeholder="Full name"
              value={props.emailFormValues.fullName}
              onChange={event =>
                props.onChange({ fullName: event.target.value })
              }
              required
              disabled={props.isLoadingSubmit}
              classes={{ formControl: props.classes.quoteFormInput }}
            />
            <Input
              placeholder="Email"
              value={props.emailFormValues.email}
              onChange={event => props.onChange({ email: event.target.value })}
              required
              disabled={props.isLoadingSubmit}
              classes={{ formControl: props.classes.quoteFormInput }}
            />
          </div>
        </Slide>
        <button
          type="submit"
          className={props.classes.ctaButton}
          disabled={props.isLoadingSubmit}
        >
          Email Quote
        </button>
      </form>
    );
  }
  return (
    <button
      type="button"
      className={props.classes.ctaButton}
      onClick={props.onClickEmailQuote}
    >
      Email Quote
    </button>
  );
};

const prices = {
  nc:	9.90,
  sw:	16.60,
  tt: 16.60,
  sg: 23.20,
};

const delivery = 165;
const fixedPrice = 440;
const priceMultiplier = 2.50;
const areaLimit = 176;

const getTotal = ({ area, turf, pro }) => {
  let total = 0;

  // turf price
  total += prices[turf] * area;

  // if pro installation
  if (pro) {
    if (area < areaLimit) {
      total += fixedPrice;
    } else {
      total += priceMultiplier * area;
    }
  }

  total += +delivery;

  return total.toFixed(2);
};

const styles = theme =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      padding: '20px 30px',
      [theme.breakpoints.up('md')]: {
        maxWidth: 720,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 560,
      },
      [theme.breakpoints.up(1281)]: {
        padding: '30px 0 30px 30px',
      },
      [theme.breakpoints.up(1921)]: {
        maxWidth: 500,
      },
    },
    header: {
      color: '#fff',
      marginBottom: 20,
      fontWeight: 600,
      fontSize: `${theme.typography.enlargePercent(1.5)}rem`,
    },
    helptext: {
      color: '#fff',
      padding: 10,
      fontSize: `${theme.typography.enlargePercent(1.2)}rem`,
      lineHeight: 1.5,
      [theme.breakpoints.up(960)]: {
        lineHeight: 1.2,
      },
    },
    label: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.2rem',
      lineHeight: 1.5,
      color: '#FFF',
    },
    ctaButton: {
      display: 'inline-block',
      padding: 10,
      margin: '10px 0',
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '1.2rem',
      lineHeight: 1.5,
      borderRadius: 10,
      backgroundColor: '#1BB25F',
      // border: 0,
      border: '1px solid #1BB25F', //
      transition: '0.4s linear', //
      width: '100%',
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'white', //
        color: '#1BB25F', //
      },
      [theme.breakpoints.up(400)]: {
        padding: '10px 25px',
        fontSize: `${theme.typography.enlargePercent(1.2)}rem`,
      },
      [theme.breakpoints.up(960)]: {
        margin: 0,
      },
    },
    title: {
      fontSize: pxToRem(24),
      marginBottom: 20,
      color: '#fff',
      textAlign: 'left',
    },
    copy: {
      fontSize: pxToRem(22),
      color: '#fff',
      marginBottom: 20,
    },
    formAndResultWrap: {
      '& > div': {
        padding: 10,
      },
    },
    buttonGroup: {
      '& > div': {
        padding: 10,
      },
    },
    wizardWrap: {
      padding: 10,
    },
    wizard: {
      border: '1px solid #fff',
      borderRadius: 20,
      padding: '30px 20px',
    },
    wizardButton: {
      width: '50%',
    },
    wizardHeader: {
      marginBottom: 5,
    },
    quoteResult: {
      textAlign: 'center',
      margin: 'auto',
    },
    quoteForm: {
      overflow: 'hidden',
    },
    quoteFormInput: {
      marginBottom: 10,
    },
    total: {
      color: '#1BB25F',
      fontSize: '1.75rem',
      fontWeight: '600',
      marginBottom: 15,
    },
  });

class DesktopQuote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quoted: false,
      area: '',
      turf: '',
      suburb: '',
      pro: false,
      fullName: '',
      email: '',
      isEmailingQuote: false,
      isLoadingSubmitEmailQuote: false,
      snack: null,
    };
  }

  calcuateQuote = () => {
    if (this.state.area && this.state.turf && this.state.suburb) {
      this.setState({ quoted: true });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const isResultJustShown =
      prevState.quoted !== this.state.quoted && this.state.quoted;
    if (isResultJustShown) {
      ensureGtag('event', 'quote_result_seen', {
        event_category: 'Quote Desktop',
      });
    }
  }

  handleChange = change => {
    this.setState(change, () => {
      if (!(this.state.area && this.state.turf && this.state.suburb)) {
        this.setState({ quoted: false });
      }
    });
  };

  handleClickEmailQuote = () => {
    ensureGtag('event', 'email_quote_open', {
      event_category: 'Quote Desktop',
      event_callback: () => this.setState({ isEmailingQuote: true }),
    });
  };

  handleSubmitEmailQuote = event => {
    event.preventDefault();
    this.setState({ isLoadingSubmitEmailQuote: true });
    const { area, turf, suburb, pro, fullName, email } = this.state;

    console.log(
      'area, turf, suburb, pro, fullName, email',
      area,
      turf,
      suburb,
      pro,
      fullName,
      email
    );

    submitForm('Email quote request', {
      customBehavior: 'email-quote-v1',
      area,
      turf,
      suburb,
      pro,
      fullName,
      email,
    })
      .then(() => {
        this.setState({
          snack: {
            variant: 'success',
            message: 'Successfully sent your quote.',
          },
          fullName: '',
          email: '',
          isEmailingQuote: false,
        });

        ensureGtag('event', 'email_quote_sent_success', {
          event_category: 'Quote Desktop',
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          snack: { variant: 'error', message: 'Error sending quote.' },
        });

        ensureGtag('event', 'email_quote_sent_fail', {
          event_category: 'Quote Desktop',
          value: `Fail: ${error.message && error.message}`,
        });
      })
      .then(() => this.setState({ isLoadingSubmitEmailQuote: false }));
  };

  handleCloseSnack = () => this.setState({ snack: null });

  render() {
    const { classes, openWizard } = this.props;
    const { quoted, fullName, email } = this.state;

    return (
      <Grid item container className={classes.root} direction="column">
        <Grid
          item
          container
          direction="row"
          className={classes.formAndResultWrap}
        >
          <Grid
            container
            item
            xs={6}
            direction="column"
            className={classes.quoteWrap}
          >
            <Typography variant="h4" component="h4" className={classes.header}>
              Get a quick quote
            </Typography>
            <Input
              name="square"
              min="2"
              type="number"
              value={this.state.area}
              onChange={event =>
                this.handleChange({ area: event.target.value })
              }
            />
            <Typography
              variant="h5"
              component="h5"
              className={classes.helptext}
            >
              square meters of
            </Typography>
            <Select
              placeholder="Select lawn"
              endAdornment={ExpandMore}
              value={this.state.turf}
              onChange={turf => this.handleChange({ turf })}
              options={[
                {
                  name: 'TifTuf',
                  value: 'tt',
                  disabled: false,
                },
                {
                  name: 'Sir Walter DNA Certified',
                  value: 'sw',
                  disabled: false,
                },
                {
                  name: 'Sir Grange',
                  value: 'sg',
                  disabled: false,
                },
                {
                  name: 'Nullarbor Couch',
                  value: 'nc',
                  disabled: false,
                },
              ]}
            />
            <Typography
              variant="h5"
              component="h5"
              className={classes.helptext}
            >
              delivered to:
            </Typography>
            <Grid item container>
              <AutoComplete
                placeholder="Search suburb"
                options={suburbOptions}
                onChange={suburb => {
                  this.handleChange({ suburb });
                }}
                className={classes.suburbs}
                value={this.state.suburb}
              />
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  checked={this.state.pro}
                  icon={
                    <CheckBoxOutlineBlankIcon
                      style={{ fill: '#fff', color: '#fff' }}
                    />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fill: '#fff' }} />}
                  onChange={event =>
                    this.setState({
                      pro: !this.state.pro,
                    })
                  }
                />
              }
              label="Professional Install"
              classes={{
                label: classes.label,
              }}
            />
            <button
              type="button"
              className={classes.ctaButton}
              onClick={this.calcuateQuote}
            >
              Calculate
            </button>
          </Grid>
          <Grid container item xs={6} direction="column" justify="center">
            {!quoted ? (
              <React.Fragment>
                <Typography className={classes.title} variant="h2">
                  Need a new lawn?
                </Typography>
                <Typography
                  className={classes.copy}
                  variant="body1"
                  color="primary"
                >
                  Get an instant quote for your project.
                </Typography>
                <Typography className={classes.copy} variant="body1">
                  Not sure which lawn? Use our wizard below.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={classes.quoteResult}>
                  <Typography
                    variant="h4"
                    component="h4"
                    className={classes.header}
                  >
                    Your quote:
                  </Typography>
                  <Typography
                    color="primary"
                    variant="h3"
                    component="h3"
                    className={classes.total}
                  >
                    ${getTotal(this.state)}
                  </Typography>
                </div>
                <EmailQuote
                  classes={classes}
                  onChange={this.handleChange}
                  onClickEmailQuote={this.handleClickEmailQuote}
                  isEmailingQuote={this.state.isEmailingQuote}
                  emailFormValues={{ fullName, email }}
                  onSubmit={this.handleSubmitEmailQuote}
                  isLoadingSubmit={this.state.isLoadingSubmitEmailQuote}
                />
                {this.state.snack && (
                  <ResultSnackbar
                    {...this.state.snack}
                    onClose={this.handleCloseSnack}
                  />
                )}
              </React.Fragment>
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.wizardWrap}>
          <div className={classes.wizard}>
            <Typography
              variant="h4"
              component="h4"
              className={classNames(classes.header, [classes.wizardHeader])}
            >
              Find the perfect lawn for you
            </Typography>
            <Typography className={classes.copy} variant="body1">
              Answer three quick questions for our recommendation.
            </Typography>
            <button
              type="button"
              className={classNames(classes.ctaButton, [classes.wizardButton])}
              onClick={openWizard}
            >
              Lawn wizard
            </button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DesktopQuote);
