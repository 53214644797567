import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'gatsby';

import Image from '../Image';
import CTAButton from '../CTAButton/CTAButton';

const styles = theme =>
  createStyles({
    contentWrap: {
      textAlign: 'center',
      padding: '15px',
      backgroundColor: '#fff',
      alignItems: 'flex-start',
      justifyContent: 'center',
      [theme.breakpoints.up(350)]: {
        padding: '45px 60px 55px',
      },
      [theme.breakpoints.up(960)]: {
        maxWidth: 350,
        padding: '35px 0 55px',
      },
    },
    header: {
      fontSize: '2rem',
      fontWeight: 600,
      [theme.breakpoints.up(400)]: {
        fontSize: `${theme.typography.enlargePercent(2)}rem`,
        [theme.breakpoints.up(960)]: {
          textAlign: 'center',
        },
      },
    },
    textWrap: {
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'center',
      [theme.breakpoints.up(350)]: {
        marginTop: 25,
      },
    },
    text: {
      marginBottom: 10,
      [theme.breakpoints.up(960)]: {
        textAlign: 'center',
      },
    },
    lastText: {
      marginBottom: 0,
    },
    childrenWrap: {
      marginTop: 10,
      marginBottom: 10,
      [theme.breakpoints.up(350)]: {
        marginTop: 25,
      },
    },
    articleTitleLink: {
      textDecoration: 'none',
    },
    cta: {
      flexDirection: 'column',
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        padding: 15,
        justifyContent: 'center',
      },
    },
    image: {
      width: '100vw',
      [theme.breakpoints.up(960)]: {
        width: '100%',
        height: 230,
    
      },
    },
    imageBlock: {
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.up(960)]: {
        width: 350,
        height: 230,

      },
    },

    buttonWrap: {
      justifyContent: 'center',
    },
  });

const CTACard = ({
  header,
  text,
  image: imageRelativePath,
  cardType = 'CTA',
  ctaHref,
  ctaState = null,
  externalLink = false,
  ctaText = 'Read more',
  classes,
  theme,
  middle = false,
  children,
  md,

}) => {
  const texts = Array.isArray(text) ? text : [text]; // Deal with multiple paragraphs text

  const title = (
    <Typography variant="h4" component="h4" className={classes.header}>
      {header}
    </Typography>
  );

  return (
    <Grid
      item
      container
      direction="column"
      className={classes.cta}
      md={md || 6}
    >
      {imageRelativePath && (
        <Grid item container className={classes.imageBlock} md={12}>
          <Image relativePath={imageRelativePath} className={classes.image} />
        </Grid>
      )}
      <Grid
        item
        container
        className={classes.contentWrap}
      >
        <Grid container item justify="center">
          {cardType === 'article' && ctaHref && (
            <Link className={classes.articleTitleLink} to={ctaHref}>
              {title}
            </Link>
          )}

          {cardType !== 'article' && title}
        </Grid>

        {text && (
          <Grid item className={classes.textWrap}>
            {texts.map((t, index) => (
              <Typography
                key={t}
                variant="body1"
                className={classNames(classes.text, {
                  [classes.lastText]: index === texts.length - 1,
                })}
              >
                {t}
              </Typography>
            ))}
          </Grid>
        )}
        {children && (
          <Grid item className={classes.childrenWrap}>
            {children}
          </Grid>
        )}
        {ctaHref && ctaText && (
          <Grid container item className={classes.buttonWrap}>
            <CTAButton
              ctaHref={ctaHref}
              ctaText={ctaText}
              destinationState={ctaState}
              btnStyles={{
                // backgroundColor: theme.palette.primary.main,
              }}
              external={externalLink}
              inverted
             
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(CTACard);
