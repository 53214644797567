import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { pxToRem } from '../theme';

const styles = theme =>
  createStyles({
    blockWrap: {
      padding: '30px 20px',
      backgroundColor: '#181A38',
      [theme.breakpoints.up(400)]: { // for smaller screen devices
        padding: '30px 20px',
      },
    },
    text: {
      fontSize: pxToRem(35),
      textAlign: 'center',
      color: theme.palette.common.white,
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(45),
        paddingBottom: 10,
      },
    },
    subtitle: {
      fontWeight: '300',
      fontSize: pxToRem(20),
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(28),
      },
    },
  });

const TextBlock = ({ text, subtitle = '', classes }) => (
  <Grid
    container
    item
    direction="column"
    alignItems="center"
    justify="center"
    className={classes.blockWrap}
  >
    <Typography className={classes.text} variant="h1" component="h1">
      {text}
    </Typography>
    {subtitle && (
      <>
        <Typography
          className={classes.subtitle}
          color="secondary"
          variant="h2"
          component="h2"
        >
          {subtitle}
        </Typography>
      </>
    )}
  </Grid>
);

export default withStyles(styles, { withTheme: true })(TextBlock);
