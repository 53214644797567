import React from 'react';
import classNames from 'classnames';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import Image from './HeroImage';
import TextBlock from '../TextBlock';
import DesktopQuote from '../Quote/DesktopQuote';

import { pxToRem } from '../../theme';

const styles = theme =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
    },
    heroWrap: {
      backgroundColor: '#e9e9e9',
      [theme.breakpoints.up(960)]: {
        position: 'relative',
      },
    },
    stickyWrap: {
      overflow: 'hidden',
      minHeight: '88vh',
    },
    childrenWrap: {
      alignSelf: 'flex-end',
    },
    heroImage: {
      padding: 0,
      margin: 0,
      height: '100%',
    },
    heroImageWrap: {
      flex: 1,
      padding: 0,
      margin: 0,
      overflow: 'hidden',
      [theme.breakpoints.up(960)]: {
        maxHeight: '100%',
      }
    },
    heroTextWrap: {
      minHeight: 80,
      width: '100%',
      backgroundColor: 'rgba(24, 26, 56, 0.7)',
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        bottom: 0,
        right: 0,
      },
    },
    heroTextWrapInner: {
      [theme.breakpoints.up('md')]: {
        padding: '20px 40px 20px 50px',
        maxWidth: 720,
        flexDirection: 'column',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 800,
      },
      [theme.breakpoints.up(1500)]: {
        padding: '20px 0 20px',
      },
      [theme.breakpoints.up(1921)]: {
        maxWidth: 880,
        paddingRight: 180,
      }
    },
    heroText: {
      color: theme.palette.common.white,
      margin: 'auto',
      fontSize: '1.4rem',
      fontWeight: 400,
      display: 'block',
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(25),
      },
      [theme.breakpoints.up(960)]: {
        margin: 0,
        padding: 0,
        fontWeight: 300,
        fontSize: pxToRem(28),
      },
      [theme.breakpoints.up(1100)]: {
        fontSize: pxToRem(37),
      },
    },
    companyNameText: {
      display: 'none',
      color: theme.palette.common.white,
      lineHeight: 1.5,
      margin: 0,
      padding: 0,
      fontSize: pxToRem(48),
      fontWeight: '600',
      [theme.breakpoints.up(960)]: {
        display: 'block',
      },
      [theme.breakpoints.up(1100)]: {
        display: 'block',
        fontSize: pxToRem(54),
      },
    },
    desktopQuote: {
      display: 'none',
      [theme.breakpoints.up(960)]: {
        display: 'flex',
      },
    },
  });

const Hero = ({
  classes,
  isSticky = true,
  children,
  heroImage,
  heroHeader,
  heroText,
  toggleLawnWizard,
  noQuote
}) => (
    <Grid container item direction="row"
      className={classes.root}
    >
      <Grid
        item
        container
        direction="column-reverse"
        xs={12}
        md={noQuote ? 12 : 6}
        lg={noQuote ? 12 : 7}
        className={classNames(classes.heroWrap, { [classes.stickyWrap]: isSticky })}
      >
        <Grid item container direction="column" className={classes.childrenWrap}>
          {children}
        </Grid>
        {heroImage && (
          <Grid item className={classes.heroImageWrap}>
            <Image relativePath={heroImage} className={classes.heroImage} />
          </Grid>
        )}
        {heroHeader && (
          <Grid item className={classes.heroHeaderWrap}>
            <TextBlock text={heroHeader} />
          </Grid>
        )}
        {heroText && (
          <Grid container item justify="flex-end"
            className={classes.heroTextWrap}>
            <Grid container item className={classes.heroTextWrapInner}>
              <Typography className={classes.companyNameText} component="h1">
                Twin View Turf
              </Typography>
              <Typography className={classes.heroText} component="h2">
                {heroText}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      {!noQuote && <Grid
        container
        item
        md={6} lg={5}
        justify="flex-start"
        className={classes.desktopQuote}
      >
        <DesktopQuote openWizard={toggleLawnWizard} />
      </Grid>
      }
    </Grid>
  );

export default withStyles(styles, { withTheme: true })(Hero);
